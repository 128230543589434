<template>
  <div class="login-main">
    <nav-bar></nav-bar>
    <section class="profile-main-start box-flex">
      <div class="container review-main text-capitalize position-relative box-flex__wrapper w-100">
        <!-- <div class="text-center">
            <h1>Reports & Bugs</h1>
        </div> -->
        <div class="center-card | d-flex flex-column position-relative mt-3">
          <div class="center-card-box | p-md-3 ">
            <div class="resturant-details border-bottom  p-md-2">
                  <h4 class="text-center">{{ $t('Reports & Bugs') }}</h4>
              </div>
            <div id="ReportBug" class="tab-pane active tab-box">
              <div class="mt-4">
                <div class="mt-4 position-relative">
                  <h6 class="mb-2">{{ $t('Title') }}</h6>
                  <input class="form-control mb-0 h50" type="text" :placeholder="$t('Enter Title')"
                    aria-label="Title" v-model="$v.reports.title.$model"
                    :class="{ 'is-invalid': $v.reports.title.$error, }" />
                  <div v-if="$v.reports.title.$error" class="invalid-feedback">
                    <span v-if="!$v.reports.title.required">{{ $t('Please enter Title', { msg: $t('Title') })
                      }}</span>
                  </div>
                </div>
                <div class="mt-4 position-relative">
                  <h6 class="mb-2">{{ $t('Description') }}</h6>
                  <input class="form-control mb-0 h50" type="text" :placeholder="$t('Enter Description')" aria-label="Email"
                    v-model="$v.reports.description.$model" :class="{
                      'is-invalid': $v.reports.description.$error,
                    }" />
                  <div v-if="$v.reports.description.$error" class="invalid-feedback">
                    <span v-if="!$v.reports.description.required">{{ $t('Please enter Description') }}</span>
                  </div>
                </div>
                <div
                  class="billing-data custom-checkbox custom-checkbox-nw">
                  <div class="d-flex justify-content-between align-items-center form-group">
                    <h6>{{ $t('Uplod File') }}</h6>
                  </div>
                 
              <div class="fileupload-main d-flex align-items-start">
                <label class="filelabel">
                  <i class="fa fa-plus"></i>
                  <input
                    accept="image/png,image/jpeg,image/jpg,video/mp4,image/gif"
                    class="FileUpload1"
                    id="FileInput"
                    @change="uploadMedia"
                    type="file"
                  />
                </label>
                <div v-if="imageUrls.length > 0">
                  <div class="uploaded-img-main">
                    <div
                      v-for="(imageUrl, key) in imageUrls"
                      :key="key"
                      class="prvie-img uploaded-img"
                    >
                      <p class="remove-flg" @click="removeMedia(key,'image')">X</p>
                      <img :src="imageUrl" class="dis-img" />
                    </div>
                  </div>
                </div>
                <div v-if="videoUrls.length > 0" class="uploaded-img-main">
                  <div
                    v-for="(videoUrl, key) in videoUrls"
                    :key="key"
                    class="uploaded-img"
                  >
                    <p class="remove-flg" @click="removeMedia(key, 'video')">X</p>
                    <video controls :src="videoUrl" class="dis-img"></video>
                  </div>
                </div>

                <!-- <div v-if="videoUrl" class="uploaded-img-main">
                  <div class="uploaded-img">
                  <video controls :src="videoUrl" class="dis-img"></video>
                  </div>
                </div> -->
              </div>

                </div>
              </div>
              <div class="deliveryaddress mt-5 profile-bottom border-top pt-3">
                <div class="full-btn px-3" id="ReportBug">
                  <button @click="ReportBug">{{ $t('Add Report') }}</button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>




<script>
import { mapActions } from 'vuex';
import { required, email } from "vuelidate/lib/validators";
import Footer from '../components/Footer.vue';
import NavBar from "@/components/NavBar.vue";


export default {
  components: { NavBar, Footer },
  data() {
    return {
      imageUrls: [],
      videoUrls : [],
      maxFiles: 3,
      selectedFiles : '',
      reports: {
        description: "",
        title: "",
        is_langauge: "en",
        file : '',
        unique_id: 0,
        device_name: 0,
        device_id: 0,
        device_model: 0
      },
    };
  },
  validations: {
    reports: {
      description: {
        required,
      },
      title: {
        required,
      },
    },
  },
  mounted() {

  },
  methods: {
    ...mapActions("user", ["postReportBug"]),
    
        ReportBug() {
          this.$v.$touch(); // Validate the form
          if (this.$v.reports.$invalid) {
            return;
          } else {
            let formData = new FormData();
            // Append the text fields (e.g., title, description)
            formData.append('title', this.reports.title);
            formData.append('description', this.reports.description);
            formData.append('is_langauge', this.reports.is_langauge);
            // Append the selected file(s) (assuming `selectedFiles` contains files)
            if (this.selectedFiles && this.selectedFiles.length > 0) {
              for (let i = 0; i < this.selectedFiles.length; i++) {
                formData.append('file[]', this.selectedFiles[i]); // Append file(s)
              }
            }

            // Append other fields as needed
            formData.append('unique_id', 0);
            formData.append('device_name', 0);
            formData.append('device_id', 0);
            formData.append('device_model', 0);

            // Send the FormData object to the API
            this.postReportBug(formData).then((data) => {
              if (data.code == 200) {
                this.$toasted.success("Report successfully submitted.", { duration: 2000 });
                this.reports.title = '';
                this.reports.description = '';
                this.selectedFiles = []; // Clear file selection if needed
                this.imageUrls = []; // Clear previewed images
                this.videoUrls = []
                this.$v.$reset();
              } else {
                this.$toasted.success(data.msg, { duration: 2000 });
              }
            });
          }
        },


    uploadMedia(e) {
      if(this.calculateImage() != true){
        return false;
      }else{
        this.selectedFiles = e.target.files;
        for (let i = 0; i < this.selectedFiles.length; i++) {
          const file = this.selectedFiles[i];
          const fileSize = file.size / 1024 / 1024;
          if (fileSize > 3) {
            alert("File is too large. Please upload files smaller than 3MB");
            continue;
          }
          this.reports.file = file.name;
          if (file.type.startsWith('image/')) {
            this.handleImageUpload(file);
          } else if (file.type.startsWith('video/')) {
            this.handleVideoUpload(file);
          }
        }
      }
  },
  calculateImage(){
      const totalFilesUploaded = this.imageUrls.length + this.videoUrls.length;
      if (totalFilesUploaded >= 3) {
          // Check if the total files uploaded exceeds the limit
          // if (totalFilesUploaded >= 3) {
          //   alert("You can upload a maximum of 3 files.");
          //   return false;
          // }
        this.$toasted.error(
            this.$t(
              "You can upload a maximum of 3 files." 
            ),
            { duration: 2000 }
          );
        return false;
      }
      return true;
  },
  handleImageUpload(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.imageUrls.push(e.target.result);
    };
    reader.readAsDataURL(file);
  },
  handleVideoUpload(file) {
    const videoUrl = URL.createObjectURL(file);
    this.videoUrls.push(videoUrl);  // Push multiple video URLs
  },
  removeMedia(key,type) {
    if (type === 'image') {
      this.imageUrls.splice(key, 1);  // Remove image by index
    } else if (type === 'video' || type === 'gif') {
      this.videoUrls.splice(key, 1);  // Remove video by index
    }
  }
  },
};
</script>

<style scoped>
.uploaded-img video{
    height: 85px;
    width: 85px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid var(--secondarydark);
}
</style>